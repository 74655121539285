import SelfServe from "../pages/tabs/SelfServe/SelfServe.jsx";

const tabs = [
    {
        label: 'Welcome',
        component: <div></div>
    },
    {
        label: 'Jobs Portal',
        component: <div></div>
    },
    {
        label: 'Self Serve',
        component: <SelfServe />
    },
];

export default tabs;