import { createTheme } from "@mui/material/styles";

const lightTheme = {
  palette: {
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },

    primary: {
      main: "#4d25ad",
      dark:'#4d25ad'
    },

    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
    },

    footer: {
      main: "#e0e0e0",
      primaryText: "rgba(0, 0, 0, 0.87)",
      secondaryText: "#9e9e9e",
    },
    inputField: {main: "rgba(0, 0, 0, 0.87)"},
    
tabIndicator:{main:'#D1C4E9'},
scrollbar:{track:"#E0E0E0", thumb:"#888"},
scrollbarFirefox:{track:"#F0F0F0", thumb:"#C0C0C0"}

  },
};

const darkTheme = {
  palette: {
    background: {
      default: "#101720",
      paper: "#2b3035",
    },
    primary: {
      main: "#4d25ad",
      dark: "#4d25ad"
    },
    footer: {
      main: "#1e2429",
      primaryText: "#fff",
      secondaryText: "#9e9e9e",
    },
    inputField: {main: "#ffffff"},
    
    tabIndicator:{main:'#E0E4FF'},
    scrollbar:{track:"#333", thumb:"#888"},
    scrollbarFirefox:{track:"#3C3F41", thumb:"#5C5F61"}
  },
};

const typography = {
  fontFamily: "Montserrat, sans-serif",
  fontWeightBold: 600,
  h5:{
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  h6:{
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  footerSecondary: {
    fontSize: "0.75rem",
  },
  body1: {
    fontSize: "0.8rem",
    fontWeight: 400
  },
  body2:{
    fontSize: "0.75rem",
  },
  body3:{
    fontSize: "0.75rem",
    fontWeight: 400
  }
};

const components = {
  MuiPaper: {
    styleOverrides: {
      root: {
        "&:not(.MuiAccordion-root)": {
          // Exclude Accordion Paper
          padding: ({ theme }) => theme.spacing(5),
        },
      },
    },
  },

  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        paddingLeft: 15,
        paddingRight: 8,
        fontWeight: 500
      },
    },
  },

  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: ({theme}) => ({
        fontSize: theme.typography.body1.fontSize,
        textTransform: 'Capitalize'
      }),
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        // borderColor: 'grey', // Default border color
      },
      root: ({theme}) => ({
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.inputField.main,
          borderWidth: "1px",
        },
        "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main, 
          borderWidth: "1px",
        },
      })
    },
  },

  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      "#root::-webkit-scrollbar": {
        width: "12px"
      },
      "#root::-webkit-scrollbar-track":{
        background: theme.palette.scrollbar.track
      },
      "#root::-webkit-scrollbar-thumb": {
        background: theme.palette.scrollbar.thumb
      },
      "#root::-webkit-scrollbar-thumb:hover":{
        background: theme.palette.grey[700]
      },

      /* Custom scrollbar styles for Firefox */
      "#root":{
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.scrollbar.thumb} ${theme.palette.scrollbarFirefox.track}`,
        borderRadius: "10px"
        }
    })
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px transparent inset !important", // Makes autofill background transparent
          WebkitTextFillColor: "inherit", // Inherit the text color from the theme
          transition: "background-color 5000s ease-in-out 0s", // Prevent autofill background flash
        },
      },
    },
  },
};

const theme = createTheme({
  colorSchemes: { light: lightTheme, dark: darkTheme },
  spacing: 2,
  typography: typography,
  components: components,
});

export default theme;
