import { Button, CircularProgress, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useCrowdAccountCreation } from "../../../api/selfServe";
import { setAlert } from "../../../store/appStore";
import { ERROR_MESSAGES, ERRORS } from "../../../utils/errorCode";
import { BT_ALLOWED_ADDRESSES } from "../../../utils/constants/selfServe";

const CrowdAccountRequest = (props) => {
    const dispatchAction = useDispatch();
    const crowAccountRequest = useCrowdAccountCreation();
  
    const { register, handleSubmit, formState: { errors }} = useForm();

    const emailValidation = {
      required: "Email is required",
      validate: {
        characters: (value) =>
         /^[a-zA-Z]+(\.[a-zA-Z]+){1,2}@[^\s@]+\.[^\s@]+$/i?.test(value) ||
          ERROR_MESSAGES[ERRORS.INVALID_MAIL],
        domain: (value) =>
          BT_ALLOWED_ADDRESSES.some((domain) =>
            value.endsWith(domain)
          ) || ERROR_MESSAGES[ERRORS.INVALID_DOMAIN],
      },
    }

    const registerTextField = (id, validations={}) => {
      return register(id, {...validations})
    }
  
    const onSubmit = async (data) => {
      let emailId = data?.email;
      let userName = emailId.split("@")[0];
      let firstName = userName.split(".")[0];
      let lastName;
      if (userName.split(".").length === 3) {
        lastName = userName.split(".")[1] + "." + userName.split(".")[2];
      } else {
        lastName = userName.split(".")[1];
      }
      let payload = [
        {
          username: userName,
          first_name: firstName,
          last_name: lastName,
          email_address: emailId,
        },
      ];
      // console.log(payload);
      let response = await crowAccountRequest.mutateAsync(payload);
      if (response.status_code === "200") {
        dispatchAction(setAlert({
          message: "Crowd Account Request Successful",
          alertLevel: "success"
        }));
      }
    };

    return (
      <Stack spacing={5}>
        <Typography variant="body3">
          A Crowd account is needed in order to log in to tools like the
          Networks{" "}
          <Link
            underline="hover"
            sx={{ fontWeight: 600 }}
            href="https://jira.tvac.bt.co.uk"
            target="_blank"
          >
            Jira
          </Link>
          &nbsp; & &nbsp;
          <Link
            underline="hover"
            sx={{ fontWeight: 600 }}
            href="https://wiki.tvac.bt.co.uk"
            target="_blank"
          >
            Wiki
          </Link>
        </Typography>

        <Typography variant="body3">
          If you have a BT/EE email, submit it below to request a Crowd account.
          You'll receive setup details via email
        </Typography>
        <br />

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            size="medium"
            placeholder="Email"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
            {...registerTextField("email", emailValidation)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment sx={{ margin: 0 }} position="start">
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{ minHeight: "34px", minWidth: "86px" }}
                      disabled={crowAccountRequest.isPending}
                      type="submit"
                    >
                      {crowAccountRequest.isPending ? (
                        <CircularProgress size={18} color="#ffffff" />
                      ) : (
                        "Request"
                      )}
                    </Button>
                  </InputAdornment>
                ),
              },
            }}
          />
        </form>
      </Stack>
    );

}

export default CrowdAccountRequest;